import questions from './quiz-questions.json';

class Quiz {
    constructor(selector) {
        this.selector = selector;
        this.content = this.selector.querySelector('[data-element="content"]');
        this.result = this.selector.querySelector('[data-element="result"]');
        this.question = this.selector.querySelector('[data-element="question"]');
        this.answers = this.selector.querySelectorAll('[data-answer]');

        this._answer = this._answer.bind(this);

        this.questions = questions;
        this.endangered = false;

        this.current_question = 1;

        this.answers.forEach(answer => {
            answer.addEventListener('click', this._answer);
        })
    }

    _answer(event) {
        event.preventDefault();
        let ans = event.target.getAttribute('data-answer');
        if(ans === 'yes') {
            this.endangered = true;
        }
        this._showNextQuestion();
    }

    _showNextQuestion() {
        this.current_question++;
        if(this.questions.hasOwnProperty('question_' + this.current_question)){
            this.question.innerText = this.questions['question_' + this.current_question];
        } else {
            if(!this.endangered) {
                this.result.innerText = 'Nie znajdujesz się w grupie ryzyka zarażenia wirusem HCV, jednak zawsze zalecamy wykonanie profilaktycznych badań. Zapytaj o możliwość wykonania badania anty-HCV swojego lekarza pierwszego kontaktu.'
            }
            this.content.style.display = 'none';
            this.result.style.display = 'block';
            window.setTimeout(() => {
                this._clearQuiz();
            }, 15000)
        }
    }
    _clearQuiz() {
        this.current_question = 1;
        this.endangered = false;
        this.question.innerText = this.questions['question_' + this.current_question];
        this.result.innerText = 'Istnieje ryzyko zakażenia, zarówno HCV jak również innymi chorobami wirusowymi np. HIV, HBV. W takiej sytuacji pomyśl o zrobieniu sobie testu na HCV. Zapytaj o możliwość wykonania badania anty-HCV swojego lekarza pierwszego kontaktu.';
        this.result.style.display = 'none';
        this.content.style.display = 'block';
    }
}

export default Quiz;