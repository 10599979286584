// import Glide from '@glidejs/glide';
// import Form from './form';
// import map from './map';
// import GLightbox from 'glightbox';
import Quiz from "../quiz";

/**
 * Created by Michał on 11.05.2017.
 */

// let lightbox = new GLightbox();

function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
        }
    });
}

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 100;
    if (document.body.clientWidth <= 600) {
        window_top = document.body.scrollTop - 50;
    }
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});

window.addEventListener('DOMContentLoaded', (event) => {

    let quiz = document.querySelector('.js-quiz');
    if(quiz !== null) {
        new Quiz(quiz);
    }

    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 400;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);
});
